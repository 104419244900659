
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';

/** Components */
import Loader from '@/components/shared/Loader.vue';

/** Interfaces */
import {
  CustomTableColumnsI,
  CustomTableFormI,
  CustomTableParamsI,
} from '../interfaces/custom-table.interfaces';

export default defineComponent({
  name: 'CustomTable',
  components: {
    Loader,
  },
  props: {
    description: {
      type: String,
      required: true,
      default: 'table description',
    },
    columns: {
      type: Array as PropType<CustomTableColumnsI[]>,
      required: true,
      default: () => [],
    },
    rows: {
      type: Array,
      required: false,
      default: () => [],
    },
    limit: {
      type: Number,
      required: false,
      default: 15,
    },
    paginator: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    noContent: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['search', 'page'],
  setup(props, { emit }) {
    const formData = ref<CustomTableFormI>(Object.create({}));

    const hasRows = computed(() => props.rows.length > 0);

    const getParams = (page: number): CustomTableParamsI => {
      return {
        page,
        limit: props.limit,
        paginator: props.paginator,
        ...formData.value,
      };
    };

    onMounted(() => {
      emit('search', getParams(1));
    });

    const onFilter = (form: CustomTableFormI) => {
      formData.value = form;
      emit('search', getParams(1));
    };

    const onPage = (page: number) => {
      if (props.paginator) {
        emit('page', page);
        emit('search', getParams(page));
      }
    };

    const onReset = () => {
      formData.value = Object.create({});
      emit('search', getParams(1));
    };

    return {
      onFilter,
      onPage,
      onReset,
      hasRows,
    };
  },
});
