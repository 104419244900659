import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-082fa565"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overflow-x-auto rounded-lg pt-4" }
const _hoisted_2 = ["aria-describedby"]
const _hoisted_3 = { class: "bg-lightSurface-50" }
const _hoisted_4 = { class: "divide-y divide-gray-300" }
const _hoisted_5 = { class: "bg-white mb-4 rounded-md py-6 text-md text-primary font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showLoading)
      ? (_openBlock(), _createBlock(_component_Loader, {
          key: 0,
          showLoader: _ctx.loading,
          styles: 'relative'
        }, null, 8, ["showLoader"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("table", {
          class: _normalizeClass(["table", { 'divide-y': _ctx.hasRows }]),
          "aria-describedby": _ctx.description
        }, [
          _createElementVNode("thead", _hoisted_3, [
            _createElementVNode("tr", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, index) => {
                return (_openBlock(), _createElementBlock("th", {
                  scope: "col",
                  class: "table__th",
                  key: index
                }, _toDisplayString(column.header), 1))
              }), 128))
            ])
          ]),
          _createElementVNode("tbody", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: column.slot,
                    class: "table__td"
                  }, [
                    _renderSlot(_ctx.$slots, column.slot, { row: row }, undefined, true)
                  ]))
                }), 128))
              ]))
            }), 128))
          ])
        ], 10, _hoisted_2), [
          [_vShow, _ctx.hasRows]
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.noContent), 1)
        ], 512), [
          [_vShow, !_ctx.hasRows]
        ])
      ])
    ], 512), [
      [_vShow, !_ctx.loading || !_ctx.showLoading]
    ])
  ], 64))
}